import React, { useEffect, useRef } from 'react'

export type SpinnerProps = {
  handleFetch: () => void
}

export const Spinner = ({ handleFetch }: SpinnerProps) => {
  const element = useRef<HTMLDivElement | null>(null)

  const handlerIntersect = (entries: any) => {
    entries.forEach((entry: any) => {
      if (entry.isIntersecting) {
        handleFetch()
      }
    })
  }
  const observer = new IntersectionObserver(handlerIntersect, {
    rootMargin: '0% 0% -80px',
  })

  useEffect(() => {
    if (element) {
      observer.observe(element.current as Element)
    }

    return () => {
      observer.disconnect()
    }
  }, [element])

  return <div ref={element} className="c-spinner"></div>
}
