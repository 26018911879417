import PubSub from 'vanilla-pubsub'

export default class {
  constructor(selector) {
    this.el = document.querySelectorAll(selector)

    if (this.el.length) {
      this.initialize()
    }
  }

  initialize() {
    this.bind()
  }

  bind() {
    PubSub.subscribe('App.scroll', ({ scrollY }) => {
      this.el.forEach((el) => {
        el.dataset.shrunk = scrollY > 1
      })
    })
  }
}
