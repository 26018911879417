import PubSub from 'vanilla-pubsub'

export default class DisableScroll {
  constructor() {
    this.scrollBarWidth =
      window.innerWidth - document.documentElement.clientWidth
    this.bind()
  }

  disable() {
    document.body.style.paddingRight = `${this.scrollBarWidth}px`
    document.body.style.overflow = 'hidden'
  }

  release() {
    document.body.style.overflow = ''
    document.body.style.paddingRight = ''
  }

  bind() {
    this.disable = this.disable.bind(this)

    PubSub.subscribe('disableScroll.disable', this.disable)
    PubSub.subscribe('disableScroll.release', this.release)
  }
}
