import Renderer from '@/assets/js/renderer'
import { GreenCompany, GreenCompanyProps } from './GreenCompany'
import API from '@cinra/media-api'

export default class {
  el: HTMLElement | null = null

  constructor(selector: string) {
    this.el = document.querySelector(selector)
    // @ts-ignore
    this.$cinra = new API.CinraService()

    if (this.el) {
      this.setup()
    }
  }

  async setup() {
    // @ts-ignore
    const { data } = await this.$cinra.$api.get('/job').catch(() => {
      return []
    })

    const render = Renderer.getRenderer(GreenCompany)
    const props: GreenCompanyProps = {
      companies: data,
    }

    render(props, this.el!)
  }
}
