import React from 'react'

export type GreenCompanyProps = {
  companies: any[]
}

export const GreenCompany = ({ companies }: GreenCompanyProps) => {
  return (
    <ul className="p-greenCompany__list">
      {companies.map((company: any) => {
        const color = company.green_company[0].acf.color
        return (
          <li key={company.id}>
            <a
              href={`https://job.cinra.net${company.link}`}
              className="p-greenCompanyItem"
            >
              <img
                src={company.acf.thumbnail.url}
                alt=""
                className="p-greenCompanyItem__image"
                decoding="async"
                loading="lazy"
              />
              <em
                className="p-greenCompanyItem__label"
                style={{ background: color }}
              >
                <i
                  className="p-greenCompanyItem__icon iconfont-check"
                  style={{ color }}
                ></i>
                {company.green_company[0].name}
              </em>
              <b className="p-greenCompanyItem__name">
                {company.title.rendered}
              </b>
            </a>
          </li>
        )
      })}
    </ul>
  )
}
