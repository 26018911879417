import Renderer from "@/assets/js/renderer";
import ArticleListTwoColumn, {
  ArticleListTwoColumnProps,
} from "./ArticleListTwoColumn";

export default class {
  el: HTMLElement | null = null;

  constructor(selector: string) {
    this.el = document.querySelector(selector);

    if (this.el) {
      this.setup();
    }
  }

  async setup() {
    const render = Renderer.getRenderer(ArticleListTwoColumn);
    const props: ArticleListTwoColumnProps = {
      slug: "",
    };

    if (this.el) {
      render(props, this.el);
    }
  }
}
