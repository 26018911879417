import { wait } from '../utils'

export default class {
  constructor(selector) {
    this.el = document.querySelector(selector)
    this.container = null
    this.text = null
    this.labels = []
    this.containerWidth = 0
    this.textWidth = 0
    this.dist = 0

    if (this.el) {
      this.initialize()
    }
  }

  initialize() {
    this.container = this.el.querySelector('.js-ticker-container')
    this.text = this.el.querySelector('.js-ticker-text')
    
    this.containerWidth = this.container.clientWidth
    this.textWidth = this.text.clientWidth

    if (this.textWidth > this.containerWidth) {
      this.setup()
    }
  }

  async setup() {
    const label = this.el.querySelector('.js-ticker-label')
    const clonedLabel = label.cloneNode(true)
    this.text.appendChild(clonedLabel)

    this.labels = [...this.el.querySelectorAll('.js-ticker-label')]

    this.dist = this.labels[1].offsetLeft

    await wait(1000)

    this.start()
  }

  start() {
    this.text.addEventListener('transitionend', async () => {
      this.text.style.transition = 'none'
      this.text.style.transform = 'translate3d(0, 0, 0)';

      await wait(3300)

      this.start()
    }, {
      once: true
    })
    this.text.style.transition = 'transform 10s linear'
    this.text.style.transform = `translate3d(${-this.dist}px, 0, 0)`;
  }
}
