import PubSub from 'vanilla-pubsub'

export default class {
  constructor() {
    this.menu = null
    this.trigger = null
    this.isExpanded = false

    this.initialize()
  }

  initialize() {
    this.menu = document.querySelector('.js-menu')
    this.trigger = document.querySelector('.js-menuTrigger')

    this.bind()
  }

  bind() {
    this.trigger.addEventListener('click', this.handleClick.bind(this))
    PubSub.subscribe('App.changeViewport', ({ isMobile }) => {
      if (!isMobile && this.isExpanded) {
        this.close()
      }
    })
  }

  handleClick() {
    if (this.isExpanded) {
      this.close()
    } else {
      this.expand()
    }
  }

  expand() {
    this.menu.setAttribute('aria-hidden', false)
    this.trigger.setAttribute('aria-expanded', true)
    document.documentElement.style.overflow = 'hidden'
    this.isExpanded = true
  }

  close() {
    this.menu.setAttribute('aria-hidden', true)
    this.trigger.setAttribute('aria-expanded', false)
    document.documentElement.style.overflow = ''
    this.isExpanded = false
  }
}
