import React from 'react'
import { render } from 'react-dom'
import { QueryClientProvider } from 'react-query'
import { queryClient } from './libs/react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

class Renderer {
  constructor() {}

  getRenderer(component: React.VFC<any>) {
    const Entry = component
    const EntryRenderer = (props: {}, targetElement: HTMLElement) => {
      return render(
        <>
          <QueryClientProvider client={queryClient}>
            {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools />}
            {Entry && <Entry {...props} />}
          </QueryClientProvider>
        </>,
        targetElement
      )
    }

    return EntryRenderer
  }
}

export default new Renderer()
