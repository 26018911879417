// Libs
import PubSub from "vanilla-pubsub";

// Utils
import { getBrowser, getOS } from "@/assets/js/utils";

// Plugins
// import WebfontLoader from './plugins/webFontLoader'

// ProjectClass (singleton)
import project from "./project";

// Modules
import Viewport from "./modules/Viewport";
import HashPosition from "./modules/HashPosition";
import Header from "./modules/Header";
import Ticker from "./modules/Ticker";
import Menu from "./modules/Menu";
import GreenCompany from "./components/GreenCompany";
import DisableScroll from "./modules/DisableScroll";
import SmoothScroll from "./modules/SmoothScroll";

// TODO 必要なページを確認してそのページでのみ読み込む
import StickyWidget from "./modules/StickyWidget";

// TODO 必要なページを確認してそのページでのみ読み込む
// Component
import ArticleListTwoColumn from "./components/ArticleListTwoColumn";

class App {
  constructor() {
    this._initialize();

    // ページごとに実行されるjs群 （for dynamic import）
    this.pages = {
      top: "top.js",
      archive: "archive.js",
      article: "article.js",
      archiveSeries: "archiveSeries.js",
      articleSeries: "articleSeries.js",
      articleSpecial: "articleSpecial.js",
      post: "post.js",
      contributor: "contributor.js",
      contributorDetail: "contributorDetail.js",
      interactive: "interactive.js",
      mypage: "mypage.js",
      terms: "terms.js",
      contact: "contact.js",
      happening: "happening.js",
    };

    // ページ共通で実行されるjs群
    this.globals = {
      viewport: new Viewport(),
      hashPosition: new HashPosition(),
      header: new Header(".js-header"),
      ticker: new Ticker(".js-ticker"),
      menu: new Menu(),
      greenCompany: new GreenCompany(".js-greenCompany"),
      disableScroll: new DisableScroll(),
      smoothScroll: new SmoothScroll(),
      reactArticleListTwoColumn: new ArticleListTwoColumn(
        ".js-articleList2col"
      ),
      StickyWidget: new StickyWidget(".js-sticky-widget"),
    };

    this._setup();
  }

  _initialize() {
    // 使用ブラウザをクラス名として追加
    const browserName = getBrowser();

    // OS と バージョン をクラス名として追加
    const os = getOS();

    document.documentElement.classList.add(browserName);
    document.documentElement.classList.add(os.family);
    document.documentElement.classList.add(`major_${os.version.major}`);
    document.documentElement.classList.add(`minor_${os.version.minor}`);
  }

  async _setup() {
    // 現在ページを取得
    const $root = document.querySelector(".js-root");
    const current = $root.dataset.page || "";

    // 対応するDynamic Importモジュールを確認
    const page = this.pages[current] || false;

    // グローバルモジュールをprojectに追加
    project.addModules(this.globals);

    // 現在ページ用のmodule郡をDynamic Importしてインスタンス化
    if (page) {
      this.currentPage = await this.importer(current);
    }

    PubSub.publish("App.ready");
    document.body.classList.add("is-app-ready");
  }

  importer(filename) {
    return (
      import(`./pages/${filename}`)
        /* eslint-disable-next-line new-cap */
        .then((Module) => new Module.default(".js-root"))
        .catch((err) => {
          console.error(err);
        })
    );
  }
}

new App();
